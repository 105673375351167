import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/flowTwo/StepThree2.module.css";
import Header2 from "./Header2";
import Footer2 from "./Footer2";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../contexts/FormDataContext";

const StepThree2 = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormDataContext);

  // Initialize states from either formData or defaults (or localStorage)
  const initialStreetAddress =
    formData.streetAddress || localStorage.getItem("streetAddress") || "";
  const initialCoverage =
    formData.coverage_type || localStorage.getItem("coverage_type") || "";

  const [contact, setContact] = useState(formData.contact || "");
  const [email, setEmail] = useState(formData.email || "");
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || "");
  const [streetAddress, setStreetAddress] = useState(initialStreetAddress);
  const [address2, setAddress2] = useState(formData.address2 || "");
  const [zipCode, setZipCode] = useState(formData.zip || "");
  const [selectedCoverage, setSelectedCoverage] = useState(initialCoverage);
  const [isHuman, setIsHuman] = useState(formData.isHuman || false);
  const [location, setLocation] = useState("your area");
  const [errors, setErrors] = useState({});

  // Load street address if not in formData but in localStorage
  useEffect(() => {
    if (!formData.streetAddress && localStorage.getItem("streetAddress")) {
      setStreetAddress(localStorage.getItem("streetAddress"));
    }
  }, [formData.streetAddress]);

  // Load coverage_type if not in formData but in localStorage
  useEffect(() => {
    if (!formData.coverage_type && localStorage.getItem("coverage_type")) {
      setSelectedCoverage(localStorage.getItem("coverage_type"));
    }
  }, [formData.coverage_type]);

  // Whenever streetAddress changes, update localStorage
  useEffect(() => {
    localStorage.setItem("streetAddress", streetAddress);
  }, [streetAddress]);

  // Whenever coverage changes, update localStorage
  useEffect(() => {
    localStorage.setItem("coverage_type", selectedCoverage);
  }, [selectedCoverage]);

  // Sync local state with formData whenever a relevant field changes
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      contact,
      email,
      phoneNumber,
      streetAddress,
      address2,
      zip: zipCode,
      coverage_type: selectedCoverage,
      isHuman,
    }));
  }, [
    contact,
    email,
    phoneNumber,
    streetAddress,
    address2,
    zipCode,
    selectedCoverage,
    isHuman,
    setFormData,
  ]);

  // Determine traffic source and initialize appropriate pixel
  useEffect(() => {
    const dsp_name = localStorage.getItem("dsp_name");
    if (dsp_name === "facebook") {
      // Ensure fbq is available globally
      fbq("track", "ViewContent");
    }
  }, []);

  // Fetch location based on ZIP code
  useEffect(() => {
    const fetchLocation = async () => {
      if (!zipCode) return;

      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
        if (!response.ok) {
          throw new Error("Invalid ZIP code or API error");
        }

        const data = await response.json();
        const state = data?.places?.[0]?.["state abbreviation"];
        const city = data?.places?.[0]?.["place name"];
        const zip = data?.["post code"];
        setLocation(`${city}, ${state}, ${zip}` || "your area");
      } catch (error) {
        console.error("Error fetching location:", error.message);
        setLocation("your area");
      }
    };

    fetchLocation();
  }, [zipCode]);

  // Validate the form fields
  const validateForm = () => {
    const newErrors = {};

    if (!streetAddress.trim())
      newErrors.streetAddress = "Street Address is required.";
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email))
      newErrors.email = "A valid email address is required.";
    if (!phoneNumber.trim() || phoneNumber.includes("_"))
      newErrors.phoneNumber = "A valid phone number is required.";
    if (!isHuman) newErrors.isHuman = "Please confirm you are human.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleNextStep = (e) => {
    e.preventDefault();
    if (validateForm()) {
      window.scrollTo(0, 0);
      navigate("/loading-deals2");
    }
  };

  return (
    <div>
      <Header2 />
      <div className={styles["quote-form-container"]}>
        <h3 className={styles["title"]}>
          <span className={styles["step-number"]}></span>Step 3/3: Contact
          Details and See Quote
        </h3>
        <form className={styles["form"]}>
          <div className={styles["form-group"]}>
            <label>Street Address</label>
            <input
              type="text"
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              placeholder="Ex: 123 Main St."
              className={errors.streetAddress ? "error" : ""}
            />
            {errors.streetAddress && (
              <span className="error-message">{errors.streetAddress}</span>
            )}
          </div>
          <div className={styles["form-group"]}>
            <label>City, State and ZIP Code</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="City, State, ZIP"
              className={errors.location ? "error" : ""}
            />
          </div>

          <div className={styles["form-group"]}>
            <label>Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ex: john@email.com"
              className={errors.email ? "error" : ""}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>
          <div className={styles["form-group"]}>
            <label>Phone Number</label>
            <InputMask
              mask="(999) 999-9999"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="(xxx) xxx-xxxx"
              className={errors.phoneNumber ? "error" : ""}
            >
              {(inputProps) => <input {...inputProps} type="tel" />}
            </InputMask>
            {errors.phoneNumber && (
              <span className="error-message">{errors.phoneNumber}</span>
            )}
          </div>

          {/* Human Confirmation Checkbox */}
          <div className={styles["form-group checkbox-group"]}>
            <label htmlFor="human-check" className={styles["checkbox-label"]}>
              I am not a robot
              <input
                type="checkbox"
                id="human-check"
                checked={isHuman}
                onChange={(e) => setIsHuman(e.target.checked)}
              />
            </label>
            {errors.isHuman && (
              <span className="error-message">{errors.isHuman}</span>
            )}
          </div>

          <h4 className={styles["coverage-title"]}>
            What type of rates do you want to see?
          </h4>
          <p className={styles["coverage-description"]}>
            Select a coverage option.
          </p>

          <table className={styles["coverage-table"]}>
            <thead>
              <tr>
                <th className={styles["coverage-header"]}></th>
                <th
                  className={`${styles["coverage-header"]} ${
                    selectedCoverage === "minimum" ? styles["selected"] : ""
                  }`}
                  onClick={() => setSelectedCoverage("minimum")}
                >
                  MINIMUM
                </th>
                <th
                  className={`${styles["coverage-header"]} ${
                    selectedCoverage === "standard" ? styles["selected"] : ""
                  }`}
                  onClick={() => setSelectedCoverage("standard")}
                >
                  STANDARD
                </th>
                <th
                  className={`${styles["coverage-header"]} ${
                    selectedCoverage === "premium" ? styles["selected"] : ""
                  }`}
                  onClick={() => setSelectedCoverage("premium")}
                >
                  PREMIUM
                </th>
              </tr>
            </thead>
            <tbody>
              {[
                {
                  label: "Bodily Injury",
                  minimum: "$25,000 / $50,000",
                  standard: "$100,000 / $300,000",
                  premium: "$250,000 / $500,000",
                },
                {
                  label: "Property Damage",
                  minimum: "$10,000",
                  standard: "$50,000",
                  premium: "$100,000",
                },
                {
                  label: "Medical",
                  minimum: "You pay",
                  standard: "Insurance pays",
                  premium: "Insurance pays",
                },
                {
                  label: "Roadside / Rental",
                  minimum: "You pay",
                  standard: "Insurance pays",
                  premium: "Insurance pays",
                },
              ].map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className={styles["row-label"]}>{row.label}</td>
                  <td
                    className={`${
                      selectedCoverage === "minimum" ? styles["highlight"] : ""
                    }`}
                    onClick={() => setSelectedCoverage("minimum")}
                  >
                    {row.minimum}
                  </td>
                  <td
                    className={`${
                      selectedCoverage === "standard" ? styles["highlight"] : ""
                    }`}
                    onClick={() => setSelectedCoverage("standard")}
                  >
                    {row.standard}
                  </td>
                  <td
                    className={`${
                      selectedCoverage === "premium" ? styles["highlight"] : ""
                    }`}
                    onClick={() => setSelectedCoverage("premium")}
                  >
                    {row.premium}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            type="submit"
            className={styles["get-quote-button1"]}
            onClick={handleNextStep}
          >
            Get Quote
          </button>
          <p className={styles["disclaimer"]}>
            By clicking Get Quote, I agree to the terms outlined below the
            button.
          </p>
          <p className={styles["legal-text"]}>
            By clicking Get Quote above and submitting your quote request, you
            represent that you are at least 18 years old, you authorize us to
            share the information you provided with our marketing partners and
            others...
          </p>
        </form>
      </div>
      <Footer2 />
    </div>
  );
};

export default StepThree2;
