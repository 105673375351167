export const vehicleType = {
  Acura: {
    ILX: ["Base", "Premium", "Technology", "A-Spec", "Other"],
    MDX: ["Base", "Technology", "A-Spec", "Type S", "Other"],
    NSX: ["Base", "Type S", "Other"],
    RDX: ["Base", "Technology", "A-Spec", "Advance", "Other"],
    RLX: ["Base", "Technology", "Advance", "Other"],
    TLX: ["Base", "Technology", "A-Spec", "Type S", "Other"],
  },
  Alfa_Romeo: {
    Giulia: ["Base", "Sprint", "Ti", "Veloce", "Quadrifoglio", "Other"],
    Stelvio: ["Base", "Sprint", "Ti", "Veloce", "Quadrifoglio", "Other"],
    "4C Spider": ["Base", "Other"],
  },
  Aston_Martin: {
    DB11: ["V8", "V12", "AMR", "Other"],
    DBS: ["Superleggera", "Volante", "Other"],
    Vantage: ["Base", "AMR", "Roadster", "Other"],
    DBX: ["Base", "707", "Other"],
  },
  Audi: {
    A3: ["Premium", "Premium Plus", "Prestige", "Other"],
    A4: ["Premium", "Premium Plus", "Prestige", "Allroad", "Other"],
    A5: ["Sportback", "Coupe", "Convertible", "S Line", "Other"],
    Q5: ["Premium", "Premium Plus", "Prestige", "Sportback", "Other"],
    Q7: ["Premium", "Premium Plus", "Prestige", "S Line", "Other"],
    Q8: ["Premium", "Premium Plus", "Prestige", "RS", "Other"],
    R8: ["Coupe", "Spyder", "V10 Performance", "Other"],
  },
  BMW: {
    "3 Series": ["320i", "330i", "M340i", "Other"],
    "5 Series": ["530i", "540i", "M550i", "Other"],
    X3: ["sDrive30i", "xDrive30i", "M40i", "Other"],
    X5: ["sDrive40i", "xDrive40i", "M50i", "Other"],
    "4 Series": ["Coupe", "Convertible", "Gran Coupe", "M440i", "Other"],
    "7 Series": ["740i", "750i", "M760i", "Other"],
  },
  Ford: {
    F150: ["XL", "XLT", "Lariat", "Platinum", "Limited", "Raptor", "Other"],
    Mustang: ["EcoBoost", "GT", "Mach 1", "Shelby GT500", "Other"],
    Escape: ["S", "SE", "SEL", "Titanium", "Other"],
    Explorer: ["Base", "XLT", "Limited", "ST", "Platinum", "Other"],
    Bronco: [
      "Base",
      "Big Bend",
      "Black Diamond",
      "Outer Banks",
      "Wildtrak",
      "Raptor",
      "Other",
    ],
  },
  Chevrolet: {
    Silverado: [
      "1500",
      "2500HD",
      "3500HD",
      "LT",
      "LTZ",
      "High Country",
      "Other",
    ],
    Malibu: ["L", "LS", "RS", "LT", "Premier", "Other"],
    Equinox: ["L", "LS", "LT", "Premier", "RS", "Other"],
    Corvette: ["Stingray", "Z06", "ZR1", "Grand Sport", "Other"],
    Camaro: ["LT", "SS", "ZL1", "Other"],
  },
  Honda: {
    Accord: ["LX", "Sport", "EX", "Touring", "Hybrid", "Other"],
    Civic: ["LX", "Sport", "EX", "Touring", "Si", "Type R", "Other"],
    CR_V: ["LX", "EX", "EX-L", "Touring", "Hybrid", "Other"],
    Pilot: ["LX", "EX", "EX-L", "Touring", "Elite", "Other"],
    Ridgeline: ["Sport", "RTL", "RTL-E", "Black Edition", "Other"],
  },
  Jeep: {
    Wrangler: ["Sport", "Sahara", "Rubicon", "Mojave", "Other"],
    Cherokee: ["Latitude", "Latitude Plus", "Limited", "Trailhawk", "Other"],
    Grand_Cherokee: [
      "Laredo",
      "Limited",
      "Overland",
      "Summit",
      "Trailhawk",
      "Other",
    ],
    Gladiator: ["Sport", "Overland", "Rubicon", "Mojave", "Other"],
  },
  Toyota: {
    Camry: ["LE", "SE", "XSE", "XLE", "TRD", "Hybrid", "Other"],
    Corolla: ["L", "LE", "XLE", "SE", "XSE", "Hybrid", "Other"],
    RAV4: [
      "LE",
      "XLE",
      "XLE Premium",
      "Adventure",
      "TRD Off-Road",
      "Hybrid",
      "Prime",
      "Other",
    ],
    Tacoma: [
      "SR",
      "SR5",
      "TRD Sport",
      "TRD Off-Road",
      "Limited",
      "TRD Pro",
      "Other",
    ],
    Tundra: [
      "SR",
      "SR5",
      "Limited",
      "Platinum",
      "1794 Edition",
      "TRD Pro",
      "Other",
    ],
  },
  Tesla: {
    "Model 3": ["Standard Range Plus", "Long Range", "Performance", "Other"],
    "Model S": ["Long Range", "Plaid", "Other"],
    "Model X": ["Long Range", "Plaid", "Other"],
    "Model Y": ["Long Range", "Performance", "Other"],
  },
  Subaru: {
    Outback: ["Base", "Premium", "Limited", "Touring", "Wilderness", "Other"],
    Forester: ["Base", "Premium", "Sport", "Limited", "Touring", "Other"],
    Impreza: ["Base", "Premium", "Sport", "Limited", "Other"],
    Crosstrek: ["Base", "Premium", "Sport", "Limited", "Hybrid", "Other"],
    WRX: ["Base", "Premium", "Limited", "GT", "STI", "Other"],
  },
  Nissan: {
    Altima: ["S", "SV", "SR", "SL", "Platinum", "Other"],
    Rogue: ["S", "SV", "SL", "Platinum", "Other"],
    Frontier: ["S", "SV", "Pro-4X", "Other"],
    Maxima: ["S", "SV", "SL", "Platinum", "Other"],
    Pathfinder: ["S", "SV", "SL", "Platinum", "Other"],
  },
  Mercedes_Benz: {
    "C-Class": ["C 300", "AMG C 43", "AMG C 63", "Other"],
    "E-Class": ["E 350", "E 450", "AMG E 53", "AMG E 63", "Other"],
    GLC: ["GLC 300", "AMG GLC 43", "AMG GLC 63", "Other"],
    GLE: ["GLE 350", "GLE 450", "AMG GLE 53", "AMG GLE 63", "Other"],
  },
  Hyundai: {
    Elantra: ["SE", "SEL", "N Line", "Limited", "Other"],
    Sonata: ["SE", "SEL", "N Line", "Limited", "Other"],
    Tucson: ["SE", "SEL", "N Line", "Limited", "Other"],
    Santa_Fe: ["SE", "SEL", "N Line", "Limited", "Other"],
  },
  Kia: {
    Forte: ["FE", "LXS", "GT", "EX", "Other"],
    Optima: ["LX", "LXS", "GT", "EX", "Other"],
    Seltos: ["LX", "S", "EX", "SX", "Other"],
    Sorento: ["LX", "S", "EX", "SX", "Other"],
  },
  Volkswagen: {
    Jetta: ["S", "SE", "SEL", "GLI", "Other"],
    Passat: ["S", "SE", "SEL", "R-Line", "Other"],
    Tiguan: ["S", "SE", "SEL", "R-Line", "Other"],
    Atlas: ["S", "SE", "SEL", "R-Line", "Other"],
  },
  Lexus: {
    IS: ["300", "350", "F Sport", "Other"],
    ES: ["250", "350", "F Sport", "Other"],
    LS: ["500", "500h", "F Sport", "Other"],
    RX: ["350", "450h", "F Sport", "Other"],
  },
  Infiniti: {
    Q50: ["Pure", "Luxe", "Sport", "Red Sport", "Other"],
    Q60: ["Pure", "Luxe", "Sport", "Red Sport", "Other"],
    QX50: ["Pure", "Luxe", "Essential", "Sensory", "Autograph", "Other"],
    QX60: ["Pure", "Luxe", "Essential", "Sensory", "Autograph", "Other"],
  },
  Jaguar: {
    XE: ["S", "SE", "HSE", "R-Dynamic", "Other"],
    XF: ["S", "SE", "HSE", "R-Dynamic", "Other"],
    F_PACE: ["S", "SE", "HSE", "SVR", "Other"],
    I_PACE: ["S", "SE", "HSE", "First Edition", "Other"],
  },
  Land_Rover: {
    Defender: ["90", "110", "S", "SE", "HSE", "X", "Other"],
    Discovery: ["S", "SE", "HSE", "R-Dynamic", "Other"],
    Range_Rover: ["Base", "HSE", "Autobiography", "SVAutobiography", "Other"],
    Range_Rover_Evoque: ["S", "SE", "HSE", "R-Dynamic", "Other"],
  },
  Porsche: {
    911: ["Carrera", "Targa", "Turbo", "GT3", "Other"],
    Cayenne: ["Base", "S", "GTS", "Turbo", "Other"],
    Panamera: ["Base", "4S", "GTS", "Turbo", "Other"],
    Taycan: ["4S", "Turbo", "Turbo S", "Other"],
    Other: ["Other"],
  },
  Ram: {
    1500: [
      "Tradesman",
      "Big Horn",
      "Laramie",
      "Rebel",
      "Limited",
      "TRX",
      "Other",
    ],
    2500: [
      "Tradesman",
      "Big Horn",
      "Laramie",
      "Limited",
      "Power Wagon",
      "Other",
    ],
    3500: [
      "Tradesman",
      "Big Horn",
      "Laramie",
      "Limited",
      "Power Wagon",
      "Other",
    ],
  },
  Volvo: {
    S60: [
      "Momentum",
      "R-Design",
      "Inscription",
      "Polestar Engineered",
      "Other",
    ],
    S90: [
      "Momentum",
      "R-Design",
      "Inscription",
      "Polestar Engineered",
      "Other",
    ],
    XC60: [
      "Momentum",
      "R-Design",
      "Inscription",
      "Polestar Engineered",
      "Other",
    ],
    XC90: [
      "Momentum",
      "R-Design",
      "Inscription",
      "Polestar Engineered",
      "Other",
    ],
  },
  Other: ["Other"],
};
