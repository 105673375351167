export const vehicleModel = {
  Acura: ["ILX", "MDX", "NSX", "RDX", "RLX", "TLX", "Other"],
  "Alfa Romeo": ["4C", "Giulia", "Stelvio", "Other"],
  "Aston Martin": [
    "DB11",
    "DBS Superleggera",
    "Rapide",
    "Vantage",
    "DBX",
    "Other",
  ],
  Audi: [
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "Q3",
    "Q5",
    "Q7",
    "Q8",
    "R8",
    "RS 3",
    "RS 5",
    "RS 7",
    "S3",
    "S4",
    "S5",
    "S6",
    "S7",
    "S8",
    "SQ5",
    "TT",
    "Other",
  ],
  Bentley: ["Bentayga", "Continental GT", "Flying Spur", "Mulsanne", "Other"],
  BMW: [
    "2 Series",
    "3 Series",
    "4 Series",
    "5 Series",
    "6 Series",
    "7 Series",
    "8 Series",
    "M2",
    "M3",
    "M4",
    "M5",
    "M8",
    "X1",
    "X2",
    "X3",
    "X4",
    "X5",
    "X6",
    "X7",
    "Z4",
    "i3",
    "i4",
    "i8",
    "iX",
    "Other",
  ],
  Buick: ["Enclave", "Encore", "Envision", "LaCrosse", "Regal", "Other"],
  Cadillac: [
    "ATS",
    "CT4",
    "CT5",
    "CT6",
    "Escalade",
    "XT4",
    "XT5",
    "XT6",
    "Other",
  ],
  Chevrolet: [
    "Blazer",
    "Bolt EV",
    "Camaro",
    "Colorado",
    "Corvette",
    "Equinox",
    "Express",
    "Impala",
    "Malibu",
    "Silverado",
    "Sonic",
    "Spark",
    "Suburban",
    "Tahoe",
    "Trailblazer",
    "Traverse",
    "Trax",
    "Other",
  ],
  Chrysler: ["300", "Pacifica", "Voyager", "Other"],
  Dodge: [
    "Challenger",
    "Charger",
    "Durango",
    "Grand Caravan",
    "Journey",
    "Other",
  ],
  Ferrari: [
    "488 GTB",
    "812 Superfast",
    "GTC4Lusso",
    "Portofino",
    "Roma",
    "SF90 Stradale",
    "Other",
  ],
  Fiat: ["124 Spider", "500", "500L", "500X", "Other"],
  Ford: [
    "Bronco",
    "EcoSport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F-150",
    "F-250",
    "F-350",
    "Fusion",
    "Mustang",
    "Ranger",
    "Super Duty",
    "Transit",
    "Maverick",
    "Other",
  ],
  Genesis: ["G70", "G80", "G90", "Other"],
  GMC: ["Acadia", "Canyon", "Sierra", "Terrain", "Yukon", "Other"],
  Honda: [
    "Accord",
    "Civic",
    "Clarity",
    "CR-V",
    "Fit",
    "HR-V",
    "Insight",
    "Odyssey",
    "Passport",
    "Pilot",
    "Ridgeline",
    "Other",
  ],
  Hyundai: [
    "Accent",
    "Elantra",
    "Ioniq",
    "Kona",
    "Nexo",
    "Palisade",
    "Santa Fe",
    "Sonata",
    "Tucson",
    "Veloster",
    "Venue",
    "Other",
  ],
  Infiniti: ["Q50", "Q60", "QX50", "QX60", "QX80", "Other"],
  Jaguar: ["E-PACE", "F-PACE", "F-TYPE", "I-PACE", "XE", "XF", "XJ", "Other"],
  Jeep: [
    "Cherokee",
    "Compass",
    "Gladiator",
    "Grand Cherokee",
    "Renegade",
    "Wrangler",
    "Other",
  ],
  Kia: [
    "Cadenza",
    "Forte",
    "K5",
    "Niro",
    "Optima",
    "Rio",
    "Seltos",
    "Sorento",
    "Soul",
    "Sportage",
    "Stinger",
    "Telluride",
    "Other",
  ],
  Lamborghini: ["Aventador", "Huracan", "Urus", "Other"],
  "Land Rover": [
    "Defender",
    "Discovery",
    "Discovery Sport",
    "Range Rover",
    "Range Rover Evoque",
    "Range Rover Sport",
    "Range Rover Velar",
    "Other",
  ],
  Lexus: ["ES", "GX", "IS", "LC", "LS", "LX", "NX", "RC", "RX", "UX", "Other"],
  Lincoln: [
    "Aviator",
    "Continental",
    "Corsair",
    "MKZ",
    "Nautilus",
    "Navigator",
    "Other",
  ],
  Lotus: ["Evora", "Evora GT", "Other"],
  Maserati: ["Ghibli", "GranTurismo", "Levante", "Quattroporte", "Other"],
  Mazda: [
    "CX-3",
    "CX-30",
    "CX-5",
    "CX-9",
    "Mazda3",
    "Mazda6",
    "MX-5 Miata",
    "Other",
  ],
  McLaren: ["540C", "570S", "600LT", "620R", "720S", "GT", "Other"],
  "Mercedes Benz": [
    "A-Class",
    "C-Class",
    "CLA",
    "CLS",
    "E-Class",
    "G-Class",
    "GLA",
    "GLB",
    "GLC",
    "GLE",
    "GLS",
    "Metris",
    "S-Class",
    "SL",
    "SLC",
    "Sprinter",
    "Other",
  ],
  MINI: ["Clubman", "Convertible", "Countryman", "Hardtop", "Other"],
  Mitsubishi: [
    "Eclipse Cross",
    "Mirage",
    "Outlander",
    "Outlander Sport",
    "Other",
  ],
  Nissan: [
    "Altima",
    "Armada",
    "Frontier",
    "Kicks",
    "LEAF",
    "Maxima",
    "Murano",
    "NV",
    "Pathfinder",
    "Rogue",
    "Sentra",
    "Titan",
    "Versa",
    "Other",
  ],
  Porsche: [
    "718 Boxster",
    "718 Cayman",
    "911",
    "Cayenne",
    "Macan",
    "Panamera",
    "Taycan",
    "Other",
  ],
  Ram: ["1500", "2500", "3500", "ProMaster", "ProMaster City", "Other"],
  Rolls_Royce: ["Cullinan", "Dawn", "Ghost", "Phantom", "Wraith", "Other"],
  Saab: ["9-3", "9-5", "Other"],
  Subaru: [
    "Ascent",
    "BRZ",
    "Crosstrek",
    "Forester",
    "Impreza",
    "Legacy",
    "Outback",
    "WRX",
    "Other",
  ],
  Tesla: ["Model 3", "Model S", "Model X", "Model Y", "Cybertruck", "Other"],
  Toyota: [
    "4Runner",
    "86",
    "Avalon",
    "C-HR",
    "Camry",
    "Corolla",
    "Corolla Hatchback",
    "Highlander",
    "Land Cruiser",
    "Mirai",
    "Prius",
    "RAV4",
    "Sequoia",
    "Sienna",
    "Supra",
    "Tacoma",
    "Tundra",
    "Yaris",
    "Other",
  ],
  Volkswagen: [
    "Arteon",
    "Atlas",
    "Atlas Cross Sport",
    "Golf",
    "Jetta",
    "Passat",
    "Tiguan",
    "Other",
  ],
  Volvo: ["S60", "S90", "V60", "V90", "XC40", "XC60", "XC90", "Other"],
  Rivian: ["R1T", "R1S", "Other"],
  Lucid: ["Air", "Gravity", "Other"],
  Polestar: ["Polestar 1", "Polestar 2", "Other"],
  Pagani: ["Huayra", "Zonda", "Other"],
  Bugatti: ["Chiron", "Divo", "Veyron", "Other"],
  Suzuki: ["Swift", "Vitara", "Jimny", "Other"],
  Other: ["Other"],
};
