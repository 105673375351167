import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/flowTwo/StepTwo2.module.css";
import Header2 from "./Header2";
import Footer2 from "./Footer2";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../contexts/FormDataContext";

const DriverInfo = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormDataContext);

  // Initialize drivers from local storage or default single-driver array
  const [drivers, setDrivers] = useState(() => {
    const savedDrivers = localStorage.getItem("drivers");
    return savedDrivers
      ? JSON.parse(savedDrivers)
      : [
          {
            firstName: "",
            lastName: "",
            dateOfBirth: "",
            insured: true,
            insuredForYear: true,
            sr22: false,
            dui: false,
            homeOwner: true,
            military: false,
            accidents: "0",
            creditScore: "Good",
            maritalStatus: "Single",
            currentInsurer: "- Select One -",
          },
        ];
  });

  const [errors, setErrors] = useState({});

  // Determine traffic source and initialize appropriate pixel
  useEffect(() => {
    const dsp_name = localStorage.getItem("dsp_name");
    if (dsp_name === "facebook") {
      fbq("track", "ViewContent");
    }
  }, []);

  // Save drivers to local storage whenever drivers state changes
  useEffect(() => {
    localStorage.setItem("drivers", JSON.stringify(drivers));
  }, [drivers]);

  const handleDateChange = (index, e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length > 8) value = value.slice(0, 8);

    if (value.length >= 3 && value.length <= 4) {
      value = `${value.slice(0, 2)}/${value.slice(2)}`;
    } else if (value.length >= 5) {
      value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;
    }

    const updatedDrivers = [...drivers];
    updatedDrivers[index].dateOfBirth = value;
    setDrivers(updatedDrivers);
  };

  const handleChange = (index, field, value) => {
    const updatedDrivers = [...drivers];
    updatedDrivers[index][field] = value;
    setDrivers(updatedDrivers);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate each driver
    drivers.forEach((driver, index) => {
      const prefix = `driver${index}`;
      if (!driver.firstName || !/^[a-zA-Z]+$/.test(driver.firstName)) {
        newErrors[`${prefix}-firstName`] =
          "First name is required and must contain only letters.";
      }

      if (!driver.lastName || !/^[a-zA-Z]+$/.test(driver.lastName)) {
        newErrors[`${prefix}-lastName`] =
          "Last name is required and must contain only letters.";
      }

      const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
      if (!dateRegex.test(driver.dateOfBirth)) {
        newErrors[`${prefix}-dateOfBirth`] =
          "Date must be in mm/dd/yyyy format.";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateForm()) {
      // Include the entire drivers array in formData
      setFormData({ ...formData, drivers });
      window.scrollTo(0, 0);
      navigate("/step-three2");
    }
  };

  const handleAddDriver = () => {
    setDrivers([
      ...drivers,
      {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        insured: true,
        insuredForYear: true,
        sr22: false,
        dui: false,
        homeOwner: true,
        military: false,
        accidents: "0",
        creditScore: "Good",
        maritalStatus: "Single",
        currentInsurer: "- Select One -",
      },
    ]);
  };

  const handleRemoveDriver = (index) => {
    const updatedDrivers = drivers.filter((_, i) => i !== index);
    setDrivers(updatedDrivers);
  };

  return (
    <div>
      <Header2 />
      <div className={styles["driver-info"]}>
        <h2>Step 2/3: Driver's Information</h2>

        {drivers.map((driver, index) => {
          const prefix = `driver${index}`;
          return (
            <div className={styles["driver-section"]} key={index}>
              <div className={styles["form-row"]}>
                <div className={styles["form-group"]}>
                  <label>First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Enter first name"
                    value={driver.firstName}
                    onChange={(e) =>
                      handleChange(index, "firstName", e.target.value)
                    }
                    className={
                      errors[`${prefix}-firstName`] ? styles["error"] : ""
                    }
                  />
                  {errors[`${prefix}-firstName`] && (
                    <span className="error-message">
                      {errors[`${prefix}-firstName`]}
                    </span>
                  )}
                </div>
                <div className={styles["form-group"]}>
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Enter last name"
                    value={driver.lastName}
                    onChange={(e) =>
                      handleChange(index, "lastName", e.target.value)
                    }
                    className={
                      errors[`${prefix}-lastName`] ? styles["error"] : ""
                    }
                  />
                  {errors[`${prefix}-lastName`] && (
                    <span className="error-message">
                      {errors[`${prefix}-lastName`]}
                    </span>
                  )}
                </div>
              </div>

              <div className={styles["form-row"]}>
                <div className={styles["form-group"]}>
                  <label>Date of Birth</label>
                  <input
                    type="text"
                    name="dateOfBirth"
                    placeholder="mm/dd/yyyy"
                    value={driver.dateOfBirth}
                    onChange={(e) => handleDateChange(index, e)}
                    className={
                      errors[`${prefix}-dateOfBirth`] ? styles["error"] : ""
                    }
                  />
                  {errors[`${prefix}-dateOfBirth`] && (
                    <span className="error-message">
                      {errors[`${prefix}-dateOfBirth`]}
                    </span>
                  )}
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>Are you currently insured?</label>
                <div className={styles["toggle-buttons"]}>
                  <button
                    className={driver.insured ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "insured", true)}
                  >
                    Yes
                  </button>
                  <button
                    className={!driver.insured ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "insured", false)}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>Have you been insured for at least a year?</label>
                <div className={styles["toggle-buttons"]}>
                  <button
                    className={
                      driver.insuredForYear ? styles["active"] : undefined
                    }
                    onClick={() => handleChange(index, "insuredForYear", true)}
                  >
                    Yes
                  </button>
                  <button
                    className={
                      !driver.insuredForYear ? styles["active"] : undefined
                    }
                    onClick={() => handleChange(index, "insuredForYear", false)}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>Current Insurer</label>
                <select
                  value={driver.currentInsurer}
                  onChange={(e) =>
                    handleChange(index, "currentInsurer", e.target.value)
                  }
                >
                  {[
                    "- Select One -",
                    "Geico",
                    "State Farm",
                    "Progressive",
                    "Allstate",
                    "Liberty Mutual",
                    "USAA",
                    "Farmers",
                    "Nationwide",
                    "American Family",
                    "Travelers",
                    "Other",
                  ].map((insurer) => (
                    <option key={insurer} value={insurer}>
                      {insurer}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles["form-group"]}>
                <label>Has your state notified you to file an SR22?</label>
                <div className={styles["toggle-buttons"]}>
                  <button
                    className={driver.sr22 ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "sr22", true)}
                  >
                    Yes
                  </button>
                  <button
                    className={!driver.sr22 ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "sr22", false)}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>Do you have any DUIs in the past 3 years?</label>
                <div className={styles["toggle-buttons"]}>
                  <button
                    className={driver.dui ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "dui", true)}
                  >
                    Yes
                  </button>
                  <button
                    className={!driver.dui ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "dui", false)}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>At fault accidents in the last 3 years?</label>
                <select
                  value={driver.accidents}
                  onChange={(e) =>
                    handleChange(index, "accidents", e.target.value)
                  }
                >
                  {["0", "1", "2", "3"].map((acc) => (
                    <option key={acc} value={acc}>
                      {acc}
                    </option>
                  ))}
                </select>
              </div>

              <div className={styles["form-group"]}>
                <label>Do you own a Home?</label>
                <div className={styles["toggle-buttons"]}>
                  <button
                    className={driver.homeOwner ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "homeOwner", true)}
                  >
                    Yes
                  </button>
                  <button
                    className={!driver.homeOwner ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "homeOwner", false)}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>Military Affiliation?</label>
                <div className={styles["toggle-buttons"]}>
                  <button
                    className={driver.military ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "military", true)}
                  >
                    Yes
                  </button>
                  <button
                    className={!driver.military ? styles["active"] : undefined}
                    onClick={() => handleChange(index, "military", false)}
                  >
                    No
                  </button>
                </div>
              </div>

              <div className={styles["form-group"]}>
                <label>Credit Score</label>
                <select
                  value={driver.creditScore}
                  onChange={(e) =>
                    handleChange(index, "creditScore", e.target.value)
                  }
                >
                  {["Excellent", "Good", "Average", "Poor"].map((score) => (
                    <option key={score} value={score}>
                      {score}
                    </option>
                  ))}
                </select>
              </div>

              {index > 0 && (
                <button
                  type="button"
                  onClick={() => handleRemoveDriver(index)}
                  className={styles["add-driver"]}
                  style={{ marginBottom: "20px" }}
                >
                  - Remove Driver
                </button>
              )}
            </div>
          );
        })}

        <button
          type="button"
          onClick={handleAddDriver}
          className={styles["add-driver"]}
          style={{ marginBottom: "20px" }}
        >
          + Add Another Driver
        </button>

        <button className={styles["next-step"]} onClick={handleNextStep}>
          Next Step
        </button>
      </div>
      <Footer2 />
    </div>
  );
};

export default DriverInfo;
