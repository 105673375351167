import React, { useState, useEffect, useContext } from "react";
import styles from "../../styles/flowTwo/StepOne2.module.css";
import Header2 from "./Header2";
import Footer2 from "./Footer2";
import { vehicleMakes } from "../../constants/vehicleMakes";
import { vehicleModel } from "../../constants/vehicleModel";
import { vehicleType } from "../../constants/vehicleType";
import { useNavigate } from "react-router-dom";
import { FormDataContext } from "../../contexts/FormDataContext";

const StepOne2 = ({ nextStep }) => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormDataContext);

  // Initialize from localStorage or default vehicle
  const [vehicles, setVehicles] = useState(() => {
    const savedVehicles = localStorage.getItem("vehicles");
    return savedVehicles
      ? JSON.parse(savedVehicles)
      : [
          {
            year: "",
            make: "",
            model: "",
            type: "",
            ownership: "Owned (not making loan payments)",
            mileage: "10,000 miles",
            primaryUse: "Commute to Work",
          },
        ];
  });

  const [errors, setErrors] = useState([]);
  const [zipCode] = useState(localStorage.getItem("zip_code") || "");
  const [location, setLocation] = useState("your area");

  useEffect(() => {
    const fetchLocation = async () => {
      if (!zipCode) return;

      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
        if (!response.ok) {
          throw new Error("Invalid ZIP code or API error");
        }

        const data = await response.json();
        const state = data?.places?.[0]?.["state"];
        setLocation(state || "your area");
      } catch (error) {
        console.error("Error fetching location:", error.message);
        setLocation("your area");
      }
    };

    fetchLocation();
  }, [zipCode]);

  useEffect(() => {
    const dspName = localStorage.getItem("dsp_name");
    if (dspName === "facebook") {
      fbq("track", "ViewContent");
    }
  }, []);

  // Update localStorage whenever vehicles changes
  useEffect(() => {
    localStorage.setItem("vehicles", JSON.stringify(vehicles));
  }, [vehicles]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedVehicles = [...vehicles];
    updatedVehicles[index][name] = value;

    if (name === "make") {
      updatedVehicles[index].model = "";
      updatedVehicles[index].type = "";
    } else if (name === "model") {
      updatedVehicles[index].type = "";
    }

    setVehicles(updatedVehicles);

    const updatedErrors = [...errors];
    updatedErrors[index] = {
      ...updatedErrors[index],
      [name]: false,
    };
    setErrors(updatedErrors);
  };

  const validateFields = (index) => {
    const vehicle = vehicles[index];
    const newErrors = {};
    if (!vehicle.year) newErrors.year = true;
    if (!vehicle.make) newErrors.make = true;
    if (!vehicle.model) newErrors.model = true;
    if (!vehicle.mileage) newErrors.mileage = true;
    if (!vehicle.primaryUse) newErrors.primaryUse = true;

    const updatedErrors = [...errors];
    updatedErrors[index] = newErrors;
    setErrors(updatedErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleAddVehicle = () => {
    setVehicles([
      ...vehicles,
      {
        year: "",
        make: "",
        model: "",
        type: "",
        ownership: "Owned (not making loan payments)",
        mileage: "10,000 miles",
        primaryUse: "Commute to Work",
      },
    ]);
    setErrors([...errors, {}]);
  };

  const handleRemoveVehicle = (index) => {
    const updatedVehicles = vehicles.filter((_, i) => i !== index);
    const updatedErrors = errors.filter((_, i) => i !== index);
    setVehicles(updatedVehicles);
    setErrors(updatedErrors);
  };

  const handleSubmit = () => {
    const isValid = vehicles.every((_, index) => validateFields(index));
    if (isValid) {
      // Include the entire vehicles array, not just year
      setFormData({ ...formData, vehicles });

      window.scrollTo(0, 0);
      navigate("/step-two2");
    }
  };

  return (
    <div>
      <Header2 />
      <div className={styles["vehicle-details-container"]}>
        <h2 className={styles["heading"]}>
          Step 1/3: Get the Best Rates in {location}!
        </h2>
        <p>
          Get your quote in just 3 simple steps. Enter your information below.
        </p>

        {vehicles.map((vehicle, index) => (
          <div key={index} className={styles["vehicle-form-section"]}>
            <div className={styles["section-header"]}>
              <span className={styles["section-number"]}>{index + 1}</span>
              <h3>About Your Vehicle {index + 1}</h3>
            </div>

            <div className={styles["form-section"]}>
              <label htmlFor={`year-${index}`}>
                Vehicle Year
                {errors[index]?.year && (
                  <span className={styles["error-message"]}></span>
                )}
              </label>
              <select
                id={`year-${index}`}
                name="year"
                value={vehicle.year}
                onChange={(e) => {
                  handleChange(index, e);
                }}
                className={errors[index]?.year ? styles["error-field"] : ""}
              >
                <option value="">Please Select</option>
                {Array.from(
                  new Array(71),
                  (_, i) => new Date().getFullYear() - i
                ).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>

              <label htmlFor={`make-${index}`}>
                Vehicle Make
                {errors[index]?.make && (
                  <span className={styles["error-message"]}></span>
                )}
              </label>
              <select
                id={`make-${index}`}
                name="make"
                value={vehicle.make}
                onChange={(e) => handleChange(index, e)}
                className={errors[index]?.make ? styles["error-field"] : ""}
              >
                <option value="">Please Select</option>
                {vehicleMakes.map((make) => (
                  <option key={make} value={make}>
                    {make}
                  </option>
                ))}
              </select>

              <label htmlFor={`model-${index}`}>
                Vehicle Model
                {errors[index]?.model && (
                  <span className={styles["error-message"]}></span>
                )}
              </label>
              <select
                id={`model-${index}`}
                name="model"
                value={vehicle.model}
                onChange={(e) => handleChange(index, e)}
                disabled={!vehicle.make}
                className={errors[index]?.model ? styles["error-field"] : ""}
              >
                <option value="">Please Select</option>
                {vehicleModel[vehicle.make]?.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>

              <label htmlFor={`type-${index}`}>Vehicle Type</label>
              <select
                id={`type-${index}`}
                name="type"
                value={vehicle.type}
                onChange={(e) => handleChange(index, e)}
                disabled={!vehicle.model}
              >
                <option value="">Optional</option>
                {vehicleType[vehicle.make]?.[vehicle.model]?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              <label htmlFor={`mileage-${index}`}>
                Average Annual Mileage
                {errors[index]?.mileage && (
                  <span className={styles["error-message"]}>
                    Please select an option
                  </span>
                )}
              </label>
              <select
                id={`mileage-${index}`}
                name="mileage"
                value={vehicle.mileage}
                onChange={(e) => handleChange(index, e)}
                className={errors[index]?.mileage ? "error-field" : ""}
              >
                <option value="">Please Select</option>
                {[...Array(10).keys()].map((i) => (
                  <option key={i} value={`${i * 5 + 5},000 miles`}>
                    {i * 5 + 5},000 miles
                  </option>
                ))}
              </select>

              <label htmlFor={`primaryUse-${index}`}>
                Primary Use
                {errors[index]?.primaryUse && (
                  <span className="error-message">Please select an option</span>
                )}
              </label>
              <select
                id={`primaryUse-${index}`}
                name="primaryUse"
                value={vehicle.primaryUse}
                onChange={(e) => handleChange(index, e)}
              >
                <option value="Commute to Work">Commute to Work</option>
                <option value="Personal Use">Personal Use</option>
                <option value="Business">Business</option>
              </select>

              <div className={styles["vehicle-buttons"]}>
                {index > 0 && (
                  <button
                    className={styles["remove-vehicle-button"]}
                    onClick={() => handleRemoveVehicle(index)}
                  >
                    - Remove Vehicle
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}

        <button
          className={styles["add-vehicle-button"]}
          onClick={handleAddVehicle}
        >
          + Add Another Vehicle and Save up to 20%
        </button>

        <button className={styles["next-step-button"]} onClick={handleSubmit}>
          Next Step
        </button>
      </div>
      <Footer2 />
    </div>
  );
};

export default StepOne2;
